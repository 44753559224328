<template>
  <CustomForm v-slot="{ errors }" @submit="onSubmit" class="w-full lg:w-1/2 relative z-[8]">
    <div
      class="w-full pt-10 sm:pt-[46px] sm:pb-10 px-[23px] sm:pr-[54px] rounded-small bg-white lg:rounded-none -mt-1 lg:mt-0"
    >
      <h5 class="text-xl/[27px] sm:text-[22px]/[26px] font-bold sm:font-semibold">
        Sign up for a Vinderkind Subscription
      </h5>
      <div v-for="(item, index) in subscription" :key="index" class="">
        <SubscriptionSection
          :errors="errors"
          :addSubscription="addSubscription"
          :subscription="item"
          :index="index"
          :allSubscription="subscription"
          @enableSection="enableSection"
        />
      </div>
      <div :class="isEnabled ? 'opacity-100' : 'opacity-50 pointer-events-none'">
        <AccessoriesInformation />
        <PaymentInformation :errors="errors" :paymentInfo="paymentInfo" page="signup"/>
        <button
          class="mt-[15px] mb-[18px] bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-90 transition-all ease-in duration-150"
        >
          <div
          v-if="spinning"
          class="px-[15px] cursor-pointer flex items-center bg-vivid-purple rounded-small justify-center w-full text-white gap-[13px] sm:gap-5 hover:opacity-85 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
          >
            <CustomLoader />
          </div>
          <div v-else>
          Subscribe & Pay ${{ Number(calculateTotal()).toFixed(2) }}
          </div>
        </button>
        <div class="text-[13px]/[17px] flex flex-col">
          <span class="text-center">By clicking on the subscribe button, you agree with our </span>
          <button @click.prevent="openModal" class="text-center text-vivid-purple">
            terms and conditions & cancellation policy.
          </button>
        </div>
      </div>
    </div>
  </CustomForm>
  <SharedModal v-model="open">
    <TermsConditions @close="closeModal" />
  </SharedModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { Form as CustomForm } from 'vee-validate'
import PaymentInformation from '@components/sign-up/PaymentInformation.vue'
import AccessoriesInformation from '@components/sign-up/AccessoriesInformation.vue'
import SubscriptionSection from '@components/sign-up/SubscriptionSection.vue'
import SharedModal from '@components/reusable/SharedModal.vue'
import TermsConditions from '@components/sign-up/TermsConditions.vue'
import CustomLoader from '@components/reusable/CustomLoader.vue'
import { usePlansStore } from '@stores/plans'
import { useOrderStore } from '@stores/sign-up'
import { useTotalPaymentStore } from '@stores/totalPayment'
import { useSignUpCartsStore } from '@stores/sign-up-cart'
import { transformData } from '@utills/helpers/validation'

export default defineComponent({
  components: {
    PaymentInformation,
    AccessoriesInformation: AccessoriesInformation,
    CustomForm,
    SubscriptionSection,
    SharedModal,
    TermsConditions,
    CustomLoader
  },
  data() {
    return {
      paymentInfo: [
        {
          placeholder: 'Expiration Date',
          field: 'expDate',
          mask: '##/##'
        },
        {
          placeholder: 'CVV',
          field: 'cvv',
          mask: '####' 
        },
        {
          placeholder: 'Billing ZIP Code',
          field: 'billing',
          mask: '#####'
        }
      ],
      isEnabled: false
    }
  },
  methods: {
    updateSubscriptionZipCode(index: number, value: string) {
      this.subscription[index].zipCode = value
    },
    enableSection(value: boolean) {
      this.isEnabled = value
    }
  },

  setup() {
    const { calculateTotalAmount, totalPayment } = useTotalPaymentStore()
    const { calculateTotal } = useSignUpCartsStore()
    const open = ref(false)
    const openModal = () => {
      open.value = true
    }
    const closeModal = () => {
      open.value = false
    }
    const { publication } = usePlansStore()
    let subscription = ref([{ zipCode: '' }])
    let spinning = ref(false)
    function addSubscription() {
      subscription.value.push({ zipCode: '' })
    }

    const signUp = useOrderStore()
    async function onSubmit(values: Object) {
      spinning.value = true
      const cloneProducts = totalPayment.products
      const products = cloneProducts.map((item) => {
        return {
          id: item.id,
          quantity: 1
        }
      })
      await signUp.submitOrder({ ...transformData(values), products }, '/subscription-confirmation')
    }

    return {
      subscription,
      publication,
      onSubmit,
      addSubscription,
      open,
      openModal,
      closeModal,
      calculateTotalAmount,
      calculateTotal,
      totalPayment,
      spinning
    }
  }
})
</script>
