<template>
  <CustomForm
    v-slot="{ errors }"
    @submit="onSubmit"
    class="w-full max-w-[524px] m-auto px-5 lg:px-0"
  >
    <div class="w-full pt-10 pb-20 sm:py-[81px]">
      <div class="transition-all duration-300 ease-linear">
        <YourInformation :yourInfo="yourInfo" :errors="errors" />
        <div class="pt-6 pb-[31px] border-b border-b-light-gray">
          <SharedCheckBox label="Sign up for Vinderkind emails" v-model="renewYear" />
        </div>
        <DeliverInformation :deliveryInfo="deliveryInfo" :errors="errors" />
        <GiftInformation :errors="errors" :index="0"  v-if="false"/>
        <PaymentInformation :errors="errors" :paymentInfo="paymentInfo" page="checkout"/>
        <button
          class="mt-[15px] mb-[18px] bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-90 transition-all ease-in duration-150"
        >
          <div
          v-if="spinning"
          class="px-[15px] cursor-pointer flex items-center bg-vivid-purple rounded-small justify-center w-full text-white gap-[13px] sm:gap-5 hover:opacity-85 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
          >
            <CustomLoader />
          </div>
          <div v-else>
          Pay ${{ Number(calculateTotal()).toFixed(2) }}
          </div>
        </button>
        <div class="text-[13px]/[17px] flex justify-center gap-1">
          <span class="text-center">By continuing you agree with our</span>
          <button @click.prevent="openModal" class="text-center text-vivid-purple">
            terms and conditions.
          </button>
        </div>
      </div>
    </div>
  </CustomForm>
  <SharedModal v-model="open">
    <TermsConditions @close="closeModal" />
  </SharedModal>
</template>

<script lang="ts">
import SharedCheckBox from '@components/reusable/SharedCheckBox.vue'
import YourInformation from '@components/sign-up/YourInformation.vue'
import DeliverInformation from '@components/sign-up/DeliverInformation.vue'
import GiftInformation from '@components/sign-up/GiftInformation.vue'
import PaymentInformation from '@components/sign-up/PaymentInformation.vue'
import { defineComponent, ref } from 'vue'
import { usePlansStore } from '@stores/plans'
import { Form as CustomForm } from 'vee-validate'
import SharedModal from '@components/reusable/SharedModal.vue'
import TermsConditions from '@components/sign-up/TermsConditions.vue'
import { useRouter } from 'vue-router'
import { emailValidation } from '@utills/helpers/validation'
import { useCartsStore } from '@stores/carts'
import CustomLoader from '@components/reusable/CustomLoader.vue'
import { useOrderStore } from '@stores/sign-up'

export default defineComponent({
  components: {
    SharedCheckBox,
    YourInformation,
    DeliverInformation,
    CustomForm,
    GiftInformation,
    PaymentInformation,
    SharedModal,
    TermsConditions,
    CustomLoader
  },
  data() {
    return {
      paymentInfo: [
        {
          placeholder: 'Expiration Date',
          field: 'expDate',
          valid: this.required,
          mask: '##/##'
        },
        {
          placeholder: 'CVV',
          field: 'cvv',
          valid: this.required,
          mask: '####' 
        },
        {
          placeholder: 'Billing ZIP Code',
          field: 'billing',
          valid: this.required,
          mask: '#####'
        }
      ],
      yourInfo: [
        {
          placeholder: 'Full name',
          field: 'name',
          valid: this.required
        },
        {
          placeholder: 'Email',
          field: 'email',
          valid: this.emailValidation
        }
      ],
      deliveryInfo: [
        {
          placeholder: 'Name',
          field: 'name',
          valid: this.required,
        },
        {
          placeholder: 'Address',
          field: 'address',
          valid: this.required,
        },
        {
          placeholder: 'Address Line 2',
          field: 'address2',
        },
        {
          placeholder: 'City',
          field: 'city',
          valid: this.required,
        },
        {
          placeholder: 'State',
          field: 'state',
          valid: this.required,
        },
        {
          placeholder: 'Zip Code',
          field: 'zip',
          valid: this.required,
          handler: true
        }
      ]
    }
  },

  setup() {
    const { carts, calculateTotal } = useCartsStore()
    const router = useRouter()
    let zipCode = ref('')
    let spinning = ref(false)
    const { publication } = usePlansStore()
    let choosePlan = ref(1)
    let validZips = ['00000']
    let renewYear = ref(false)

    function required(value: string) {
      return value ? true : 'This field is required'
    }

    function zipValidation(value: string) {
      return value === '00000' ? true : 'Please enter a valid ZIP code'
    }

    const signUp = useOrderStore()

    async function onSubmit(values: any) {
      spinning.value = true
      const customer = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        // signUp: signup
      }

      const paymentInfo = {
        name: values.cartName,
        cardNumber: values.cardNumber,
        exp: values.expDate,
        
        cvv: values.cvv,
        zip: values.billing
      }
      const address = {
        city: values.city,
        name: values.name,
        state: values.state,
        zip: values.zip,
        address_line_1: values.address,
        address_line_2: values.address2,
      }

      const data = {
        paymentInfo,
        customer,
        products:carts,
        address,
      };

      await signUp.submitOrder(data, '/order-confirmation')
    }
    async function _onSubmit() {
      router.push('/order-confirmation')
    }

    const updateChoosePlan = (planId: number) => {
      choosePlan.value = planId
    }
    const open = ref(false)
    const openModal = () => {
      open.value = true
    }
    const closeModal = () => {
      open.value = false
    }
    return {
      validZips,
      publication,
      choosePlan,
      updateChoosePlan,
      renewYear,
      calculateTotal,
      required,
      onSubmit,
      zipValidation,
      emailValidation,
      zipCode,
      open,
      openModal,
      closeModal,
      spinning
    }
  }
})
</script>
